import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './pages/HomePage';

const getUserLanguage = () => {
  const browserLang = navigator.language.toLowerCase().split('-')[0];
  return ['en', 'ru'].includes(browserLang) ? browserLang : 'en';
};

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Redirect root to language-specific route */}
          <Route 
            path="/" 
            element={<Navigate to={`/${getUserLanguage()}`} replace />} 
          />
          
          {/* Language-specific routes */}
          <Route path="/en" element={<HomePage lang="en" />} />
          <Route path="/ru" element={<HomePage lang="ru" />} />
          
          {/* Catch all redirects to preferred language */}
          <Route path="*" element={<Navigate to={`/${getUserLanguage()}`} replace />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
