import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import buildindgLogo from '../assets/building.svg';
import myAvatar from '../assets/me.png';
import githubIcon from '../assets/github.svg';
import telegramIcon from '../assets/telegram.svg';

const translations = {
  en: {
    name: "Matsnev Gregory",
    role: "AI Developer & AI Consultant",
    description: "AI Developer and Consultant specializing in machine learning and neural networks. Learn more about my AI expertise and collaboration opportunities.",
    keywords: "AI Developer, AI Consultant, Machine Learning, Neural Networks, Gregory Matsnev",
    langSwitch: "RU",
    metaTitle: "Gregory Matsnev - AI Developer & AI Consultant",
    metaDescription: "Professional AI Developer and Consultant. Expertise in machine learning, neural networks, and AI solutions.",
    company: "AllSee",
    githubAlt: "Visit my GitHub profile",
    telegramAlt: "Contact me on Telegram",
    avatarAlt: "Gregory Matsnev portrait"
  },
  ru: {
    name: "Мацнев Григорий",
    role: "AI Разработчик & AI Консультант",
    description: "AI Разработчик и Консультант, специализирующийся на машинном обучении и нейронных сетях. Узнайте больше о моей экспертизе в области ИИ и возможностях сотрудничества.",
    keywords: "AI Разработчик, ИИ Консультант, Машинное обучение, Нейронные сети, Григорий Мацнев",
    langSwitch: "EN",
    metaTitle: "Григорий Мацнев - AI Разработчик и Консультант",
    metaDescription: "Профессиональный AI разработчик и консультант. Эксперт в области машинного обучения, нейронных сетей и AI решений.",
    company: "AllSee",
    githubAlt: "Посетите мой профиль GitHub",
    telegramAlt: "Свяжитесь со мной в Telegram",
    avatarAlt: "Портрет Григория Мацнева"
  }
};

const HomePage = ({ lang = 'en' }) => {
  const navigate = useNavigate();
  const t = translations[lang];
  const alternateUrl = `https://matsnev.pro/${lang === 'en' ? 'ru' : 'en'}`;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": t.name,
    "jobTitle": t.role,
    "url": `https://matsnev.pro/${lang}`,
    "sameAs": [
      "https://github.com/PE51K",
      "https://t.me/gregory1m",
      "https://allsee.team"
    ],
    "image": "https://matsnev.pro/me.png",
    "worksFor": {
      "@type": "Organization",
      "name": "AllSee",
      "url": "https://allsee.team"
    }
  };

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{t.metaTitle}</title>
        <meta name="description" content={t.metaDescription} />
        <meta name="keywords" content={t.keywords} />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content={t.metaTitle} />
        <meta property="og:description" content={t.metaDescription} />
        <meta property="og:image" content="https://matsnev.pro/me.png" />
        <meta property="og:url" content={`https://matsnev.pro/${lang}`} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={lang === 'en' ? 'en_US' : 'ru_RU'} />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t.metaTitle} />
        <meta name="twitter:description" content={t.metaDescription} />
        <meta name="twitter:image" content="https://matsnev.pro/me.png" />
        
        {/* Language alternates */}
        <link rel="canonical" href={`https://matsnev.pro/${lang}`} />
        <link rel="alternate" hrefLang={lang === 'en' ? 'ru' : 'en'} href={alternateUrl} />
        <link rel="alternate" hrefLang="x-default" href="https://matsnev.pro/en" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>

      <div className="flex flex-col min-h-screen bg-neutral-900 text-white font-sans relative">
        <header className="p-4 flex justify-end">
          <nav>
            <button 
              onClick={() => navigate(`/${lang === 'en' ? 'ru' : 'en'}`)}
              className="px-3 py-1 rounded-md bg-neutral-800 hover:bg-neutral-700 transition-colors"
              aria-label={`Switch to ${lang === 'en' ? 'Russian' : 'English'} language`}
            >
              {t.langSwitch}
            </button>
          </nav>
        </header>

        <main className="flex-grow content-center" role="main">
          <article className="flex flex-col items-center mt-10 gap-4">
            <img
              src={myAvatar}
              alt={t.avatarAlt}
              className="rounded-full w-40 h-40 mb-4"
              width="160"
              height="160"
            />
            <h1 className="text-3xl font-bold">{t.name}</h1>
            <p className="text-xl">{t.role}</p>
            <div className="mt-4 flex gap-4 items-center">
              <img 
                src={buildindgLogo} 
                className="w-6 h-6" 
                alt="Company" 
                width="24"
                height="24"
              />
              <Link 
                to="https://allsee.team" 
                className="text-white font-semibold text-2xl hover:text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t.company}
              </Link>
            </div>
          </article>
        </main>

        <nav className="mt-6 flex gap-4 items-center justify-center w-full">
          <Link 
            to="https://github.com/PE51K" 
            className="hover:opacity-80 transition-opacity"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t.githubAlt}
          >
            <img src={githubIcon} alt="GitHub" className="w-6 h-6" width="24" height="24" />
          </Link>
          <Link 
            to="https://t.me/gregory1m" 
            className="hover:opacity-80 transition-opacity"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t.telegramAlt}
          >
            <img src={telegramIcon} alt="Telegram" className="w-6 h-6" width="24" height="24" />
          </Link>
        </nav>

        <footer className="relative bg-neutral-900 p-4 text-center mt-10">
          <div className="absolute bottom-0 left-0 right-0 p-2 bg-neutral-900 transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100">
            <Link 
              to="https://timetagger.matsnev.pro" 
              className="text-blue-400 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              TimeTagger
            </Link>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;
